export const SuccessErrorConst = {

    loginAgin: 'Please login Again!',
    pleaseFillRequiredData: 'Please fill all the required data!',
    addedSuccess: 'Added Successfully!',
    updatedSuccess: 'Updated Successfully!',
    addBadge: 'Fistly Add Badge!',
    deleteSuccess: 'Delete Successfully!',

    messageSuccess: 'Message sent successfully!'

}
